<template>
  <router-view v-slot="{ Component, route }">
    <div
      v-if="!route.meta.headless"
      class="relative flex h-full flex-1 flex-row gap-2 overflow-hidden bg-gray-50"
    >
      <!--        <home-header></home-header>-->
      <el-menu
        collapse
        :default-active="nav?.toString()"
        router
        class="top-menu bg-transparent"
      >
        <el-menu-item
          v-for="item in menuList"
          :key="item.path"
          :index="item.name?.toString()"
          :route="{ name: item.name }"
        >
          <iconify-icon
            v-if="item.meta?.icon"
            :icon="item.meta?.icon"
            class="menu-icon"
          />
          <template #title>
            <span>{{ item.meta?.title }}</span>
          </template>
        </el-menu-item>
      </el-menu>
      <div class="flex-1 overflow-hidden bg-white">
        <component :is="Component"></component>
      </div>
    </div>
    <component v-else :is="Component"></component>
  </router-view>
  <el-dialog
    v-model="drawerVisible"
    title="登录"
    :with-header="false"
    align-center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    center
    lock-scroll
  >
    <login-form :loading="loading" :error="formError" @submit="handleSubmit" />
  </el-dialog>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useUserState } from "@/stores";
import { IndexRoutes } from "@/routes";

const route = useRoute();
const nav = computed(() => route.meta.nav || route.name);
// console.log("app route", IndexRoutes);
const menuList = computed(() =>
  IndexRoutes.filter((it) => !it?.meta?.hideInMenu),
);
const drawerVisible = computed(() => {
  if (status.value === 401) {
    return true;
  }
  if (!requiredAuth.value) {
    return false;
  }
  if (!token.value.text) {
    return true;
  }
  const expiredAt = token.value.expiredAt;
  if (typeof expiredAt === "number" && expiredAt * 1000 > Date.now()) {
    return false;
  }
  return true;
});

const store = useUserState();

const { loading, status, token, requiredAuth, formError } = storeToRefs(store);
// watchEffect(() => {
//   console.log("status change", status.value);
//   if (status.value === 401) {
//     drawerVisible.value = true;
//   }
// });
const handleSubmit = async (param: LoginDTO) => {
  await store.login(param);
};
onBeforeMount(() => {
  if (!requiredAuth.value) {
    return;
  }
  if (!token.value.text) {
    const text = localStorage.getItem("token");
    if (text) {
      store.cacheToken(text, false);
    }
  }
  if (!drawerVisible.value) {
    // 没有弹出登录框 意味着本地存在有效的 token
    // 请求 health 接口 将 token 写入 cookie
    // eventsource 需要使用 cookie 携带 token
    store.health();
  }
});
</script>
<style scoped lang="scss">
.top-menu {
  // 菜单总宽度 63px = padding * 2 + font-size
  :deep(.el-menu-item) {
    --el-menu-base-level-padding: 17px;
  }

  .menu-icon {
    --el-menu-icon-width: 29px;
    font-size: var(--el-menu-icon-width);
  }
}
</style>
