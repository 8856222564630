import { RouteRecordRaw } from "vue-router";
import Bell from "@iconify-icons/ep/bell";
import Search from "@iconify-icons/ep/search";
import Document from "@iconify-icons/ep/document";

const prefix = "/log/ble";
const nav = "log";
export const BLELogRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "ble-log",
  meta: { nav, title: "蓝牙日志", icon: Document },
  redirect: { name: "ble-log-query" },
  children: [
    {
      path: `${prefix}/query`,
      name: "ble-log-query",
      meta: { nav, title: "查询", icon: Search },
      component: () => import("@/page/log/ble/ble-log-query.vue"),
    },
    {
      path: `${prefix}/subscribe`,
      name: "ble-log-subscribe",
      meta: { nav, title: "订阅", icon: Bell },
      component: () => import("@/page/log/ble/ble-log-subscribe.vue"),
    },
  ],
};
