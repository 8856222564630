import { RouteRecordRaw } from "vue-router";
import Search from "@iconify-icons/ep/search";
import Document from "@iconify-icons/ep/document";

const prefix = "/log/upgrade";
const nav = "log";
export const UpgradeLogRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "upgrade-log",
  meta: { nav, title: "升级日志", icon: Document },
  redirect: { name: "upgrade-log-query" },
  children: [
    {
      path: `${prefix}/query`,
      name: "upgrade-log-query",
      meta: { nav, title: "查询", icon: Search },
      component: () => import("@/page/log/upgrade/upgrade-log-query.vue"),
    },
    // {
    //   path: `${prefix}/subscribe`,
    //   name: "upgrade-log-subscribe",
    //   meta: { nav, title: "订阅", icon: Bell },
    //   component: () => import("@/page/log/upgrade/upgrade-log-subscribe.vue"),
    // },
  ],
};
