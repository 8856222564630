import client from "@/plugins/axios";

export async function login(
  param: LoginDTO,
): Promise<ApiResult<string, LoginDTO>> {
  const { data } = await client.post(`/user/login`, param);
  return data;
}

export async function health(): Promise<{ status: "UP" }> {
  const { data } = await client.get("/actuator/health");
  return data;
}
