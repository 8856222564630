const hex_bit = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
];

const asciiDecoder = new TextDecoder("ascii");
const utf8Encoder = new TextEncoder();
const utf8Decoder = new TextDecoder("utf-8");

export function writeInt(
  array: Uint8Array,
  offset: number,
  length: number,
  value: number,
) {
  if (array.length < offset + length) {
    throw new Error(
      `字节集空间不够,剩余空间：${array.length - offset}. 期望空间：${length}`,
    );
  }
  let index = offset;
  for (let i = length - 1; i > 0; i--) {
    array[index++] = (value >>> (i * 8)) & 0xff;
  }
  array[index] = value & 0xff;
}

/**
 * number 转换成 bytes
 * @param int 待转换的 number
 * @param size 要转换成的bytes长度
 * @returns
 */
export function intToBytes(int: number, size: number): Uint8Array {
  const array = Array.from(Array(size))
    .map((_, idx) => size - idx - 1)
    .map((it) => it * 8)
    .map((it) => int >>> it)
    .map((it) => it & 0xff);
  return Uint8Array.from(array);
}

export function readSmallInt(
  array: Uint8Array,
  offset: number,
  length: number,
): number {
  let result = 0;
  let index = offset;
  for (let i = length - 1; i > 0; i--) {
    result |= array[index++] << (i * 8);
  }
  result |= array[index];
  return result;
}

export function readInt(
  array: Uint8Array,
  offset: number,
  length: number,
  isUnsigned: boolean,
): number {
  if (length < 4 || (length === 4 && !isUnsigned)) {
    return readSmallInt(array, offset, length);
  }
  const view = new DataView(array.buffer);
  if (length === 4) {
    return view.getUint32(offset);
  }
  if (length > 6) {
    offset += length - 6;
    length = 6;
    console.error("有溢出风险，跳过部分字节");
  }
  const highLength = length - 4;
  const h = readInt(array, offset, highLength, isUnsigned);
  const l = view.getUint32(offset + highLength);

  return h * (0xffffffff + 1) + l;
}

export function encodeFromAscii(text: string): Uint8Array {
  const buf = new ArrayBuffer(text.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < text.length; i++) {
    bufView[i] = text.charCodeAt(i);
  }
  return bufView;
}

export function decodeToAscii(array: Uint8Array): string {
  return asciiDecoder.decode(array);
}

export function decodeToUtf8(array: Uint8Array): string {
  return utf8Decoder.decode(array);
}

export function encodeFromUtf8(text: string): Uint8Array {
  return utf8Encoder.encode(text);
}

export function decodeToHex(array: Uint8Array): string {
  const result: string[] = [];
  array.forEach((it) => {
    const h = (it >>> 4) & 0xf;
    result.push(hex_bit[h]);
    const l = it & 0xf;
    result.push(hex_bit[l]);
  });
  return result.join("");
}

function decodeHexFromChar(char: string): number {
  const ascii = char.charCodeAt(0);
  return decodeHexFromAscii(ascii);
}

function decodeHexFromAscii(ascii: number): number {
  // 数字 0-9
  if (ascii <= 57 && ascii >= 48) {
    return ascii - 48;
  }
  // 大写字母 A-F
  if (ascii <= 70 && ascii >= 65) {
    return ascii - 55;
  }
  // 小写字母 a-f
  if (ascii <= 102 && ascii >= 97) {
    return ascii - 87;
  }
  throw new Error(`无效的Hex字符:${String.fromCharCode(ascii)}`);
}

export function encodeFromHex(text: string): Uint8Array {
  if (!text || !text.length) {
    return Uint8Array.of();
  }
  const size = text.length >>> 1;
  let i = text.length % 2;
  const result = new Uint8Array(size + i);
  if (i !== 0) {
    result[0] = decodeHexFromAscii(text.charCodeAt(0));
  }
  for (; i < size; i++) {
    const index = i << 1;
    let value = decodeHexFromAscii(text.charCodeAt(index)) << 4;
    value |= decodeHexFromAscii(text.charCodeAt(index + 1));
    result[i] = value;
  }

  return result;
}

export function parseFromBcd(array: Uint8Array): number[] {
  const code: number[] = [];
  array.forEach((it) => {
    code.push((it >>> 4) & 0xf);
    code.push(it & 0xf);
  });
  return code;
}

export function encodeToBcd(array: Uint8Array): string {
  const code = parseFromBcd(array);
  return code.join("");
}

function decodeBcdFromAscii(ascii: number): number {
  // 数字 0-9
  if (ascii <= 57 && ascii >= 48) {
    return ascii - 48;
  }
  throw new Error(`无效的Hex字符:${String.fromCharCode(ascii)}`);
}

export function decodeFromBcd(text: string): Uint8Array {
  const size = text.length >>> 1;
  let i = text.length % 2;
  const result = new Uint8Array(size + i);
  if (i !== 0) {
    result[0] = decodeBcdFromAscii(text.charCodeAt(0));
  }
  for (; i < size; i++) {
    const index = i << 1;
    let value = decodeBcdFromAscii(text.charCodeAt(index)) << 4;
    value |= decodeBcdFromAscii(text.charCodeAt(index + 1));
    result[i] = value;
  }

  return result;
}

// ArrayBuffer转16进度字符串示例
export function ab2hex(buffer: ArrayBuffer): string {
  const hexArr = Array.prototype.map.call(
    new Uint8Array(buffer),
    function (bit) {
      return ("00" + bit.toString(16).toUpperCase()).slice(-2);
    },
  );
  return hexArr.join("");
}
