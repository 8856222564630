import { RouteRecordRaw } from "vue-router";
import Bell from "@iconify-icons/ep/bell";
import MenuIcon from "@iconify-icons/ep/copy-document";
import House from "@iconify-icons/mdi/house-automation";

const prefix = "/station-workspace/group";
const nav = "station-workspace";
export const StationWorkspaceRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "station-workspace",
  meta: { nav, title: "遥测站点", icon: House },
  component: () =>
    import("@/components/telemeter/station/station-group-tree-view.vue"),
  props: (route) => {
    const { groupId, stationId } = route.params;
    if (stationId) {
      return {
        groupId: parseInt(groupId as string),
        stationId: parseInt(stationId as string),
      };
    }
    if (groupId) {
      return {
        groupId: parseInt(groupId as string),
      };
    }
    return {};
  },
  children: [
    {
      path: `${prefix}/:groupId(\\d+)`,
      name: "station-group-detail-ws",
      meta: { nav, title: "分组详情", icon: MenuIcon },
      props: (route) => {
        const id = parseInt(route.params.groupId as string);
        return { id, canBack: false };
      },
      component: () =>
        import("@/page/telemeter/station/station-group-detail-page.vue"),
    },
    {
      path: `${prefix}/:groupId(\\d+)/station/:stationId(\\d+)`,
      name: "station-detail-ws",
      meta: { nav, title: "测站详情", icon: Bell, hideInMenu: true },
      props: (route) => {
        const id = parseInt(route.params.stationId as string);
        return { id, canBack: false, tab: route.hash.replace("#", "") };
      },
      component: () =>
        import("@/page/telemeter/station/station-detail-page.vue"),
    },
  ],
};
