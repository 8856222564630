import { RouteRecordRaw } from "vue-router";
import Document from "@iconify-icons/ep/document";
import { FlyLogRoute } from "@/routes/log/fly-log";
import { DatagramLogRoute } from "@/routes/log/datagram-log";
import { BLELogRoute } from "@/routes/log/ble-log";
import { SCSW008LogRoute } from "@/routes/log/scsw008-log";
import { SL651LogRoute } from "@/routes/log/sl651-log";
import { SLT427LogRoute } from "@/routes/log/slt427-log";
import { UpgradeLogRoute } from "@/routes/log/upgrade-log";

export const LogRoutes: RouteRecordRaw[] = [
  FlyLogRoute,
  DatagramLogRoute,
  BLELogRoute,
  UpgradeLogRoute,
  SCSW008LogRoute,
  SL651LogRoute,
  SLT427LogRoute,
];
export const LogRoute: RouteRecordRaw = {
  path: "/log",
  name: "log",
  redirect: {
    name: "datagram-log",
  },
  meta: { nav: "log", title: "日志管理", icon: Document },
  component: () => import("@/components/common/page-menu-view.vue"),
  props: { routes: LogRoutes },
  children: LogRoutes,
};
