import { RouteRecordRaw } from "vue-router";
import Bell from "@iconify-icons/ep/bell";
import MenuIcon from "@iconify-icons/ep/copy-document";
import CpuIcon from "@iconify-icons/ep/cpu";

const prefix = "/device-workspace/group";
const nav = "device-workspace";
export const DeviceWorkspaceRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "device-workspace",
  meta: { nav, title: "遥测设备", icon: CpuIcon },
  component: () =>
    import("@/components/telemeter/device/device-group-tree-view.vue"),
  props: (route) => {
    const { groupId, deviceId } = route.params;
    if (deviceId) {
      return {
        groupId: parseInt(groupId as string),
        deviceId: parseInt(deviceId as string),
      };
    }
    if (groupId) {
      return {
        groupId: parseInt(groupId as string),
      };
    }
    return {};
  },
  children: [
    {
      path: `${prefix}/:groupId(\\d+)`,
      name: "device-group-detail-ws",
      meta: { nav, title: "分组详情", icon: MenuIcon },
      props: (route) => {
        const id = parseInt(route.params.groupId as string);
        return { id, canBack: false };
      },
      component: () =>
        import("@/page/telemeter/device/device-group-detail-page.vue"),
    },
    {
      path: `${prefix}/:groupId(\\d+)/device/:deviceId(\\d+)`,
      name: "device-detail-ws",
      meta: { nav, title: "设备详情", icon: Bell, hideInMenu: true },
      props: (route) => {
        const id = parseInt(route.params.deviceId as string);
        return { id, canBack: false, tab: route.hash.replace("#", "") };
      },
      component: () => import("@/page/telemeter/device/device-detail-page.vue"),
    },
  ],
};
