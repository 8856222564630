import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { LogRoute } from "@/routes/log";
import { TelemeterRoute } from "@/routes/telemeter";
import { DeviceWorkspaceRoute } from "@/routes/device-workspace";
import { StationWorkspaceRoute } from "@/routes/station-workspace";

export const IndexRoutes: RouteRecordRaw[] = [
  TelemeterRoute,
  LogRoute,
  DeviceWorkspaceRoute,
  StationWorkspaceRoute,
];

export const IndexRoute: RouteRecordRaw = {
  path: "/",
  name: "home",
  redirect: {
    name: TelemeterRoute.name,
  },
  children: IndexRoutes,
};

export default createRouter({
  history: createWebHistory(),
  routes: [IndexRoute],
});
