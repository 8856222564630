import { defineStore } from "pinia";
import { ref } from "vue";
import { useAxiosStatus } from "@/plugins/axios";
import { health, login } from "@/api/httpServices/login-log";
import { isSuccess } from "@/api/models/common";

export const useUserState = defineStore("user", () => {
  const loading = ref(false);
  const requiredAuth = ref(false);
  const formError = ref<FormError<LoginDTO>>({});
  const { status, token, listeners } = useAxiosStatus();
  const handleLogin = async (param: LoginDTO) => {
    loading.value = true;
    requiredAuth.value = true;
    const result = await login(param);
    loading.value = false;
    if (isSuccess(result)) {
      requiredAuth.value = false;
      cacheToken(result.data, true);
    } else {
      formError.value = result.data || {};
    }
  };

  const handleHealth = async () => {
    return await health();
  };
  const cacheToken = (value: string, local: boolean) => {
    if (value) {
      if (local) {
        localStorage.setItem("token", value);
      }
      token.text = value;
      listeners.value.forEach((it) => it());
      const pairs = value.split(".");
      const payloadStr = atob(pairs[1]);
      console.log(payloadStr);
      const payload = JSON.parse(payloadStr);
      token.expiredAt = payload.exp;
    }
  };
  return {
    token,
    status,
    loading,
    login: handleLogin,
    health: handleHealth,
    cacheToken,
    requiredAuth,
    formError,
  };
});
