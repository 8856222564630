/* eslint-disable */
// "use strict";
// import Vue from 'vue';
import axios, { AxiosError, CreateAxiosDefaults } from "axios";
import { reactive, ref } from "vue";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const baseUrl = import.meta.env.VITE_API_BASE_URL;

let config: CreateAxiosDefaults = {
  // baseURL: process.env.baseURL || process.env.apiUrl || "",
  baseURL: baseUrl,
  timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  paramsSerializer: {
    visitor(value, key, path, helpers) {
      // 过滤掉空字符串
      if (typeof value === "string" && value === "") {
        return false;
      }

      return helpers.defaultVisitor.call(this, value, key, path, helpers);
    },
  },
};

const status = ref(200);
const listeners = ref<(() => void)[]>([]);
const token = reactive({
  text: "",
  expiredAt: 0,
});

export function useAxiosStatus() {
  return { status, listeners, token };
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    config.headers["token"] = token.text;
    config.withCredentials = true;
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    // if (response.config.url !== "/user/login") {
    status.value = response.status;
    // }
    // console.log("response", response);
    return response;
  },
  function (error: AxiosError) {
    // Do something with response error
    console.log("response error", error);
    if (error.isAxiosError) {
      return handleAxiosError(error);
    }
    return Promise.reject(error);
  },
);

function handleAxiosError(error: AxiosError) {
  const response = error.response;
  if (!response) {
    return Promise.reject(error);
  }
  console.log("response status", response.status);
  if (response.status === 401) {
    status.value = response.status;
    const config = error.config;
    if (!config) {
      return Promise.reject(error);
    }
    return new Promise((resolve) => {
      listeners.value.push(() => {
        _axios.request(config).then(resolve);
      });
    });
  }
  return Promise.reject(error);
}

// Plugin.install = function(Vue, options) {
//   Vue.axios = _axios;
//   window.axios = _axios;
//   Object.defineProperties(Vue.prototype, {
//     axios: {
//       get() {
//         return _axios;
//       }
//     },
//     $axios: {
//       get() {
//         return _axios;
//       }
//     },
//   });
// };

// Vue.use(Plugin)

// export default Plugin;

export default _axios;
