import { RouteRecordRaw } from "vue-router";
import CarBattery from "@iconify-icons/mdi/car-battery";
import RainIcon from "@iconify-icons/mdi/weather-heavy-rain";
import Water from "@iconify-icons/mdi/water-outline";
import WaterFlow from "@iconify-icons/mdi/water-flow";
import WaterLevel from "@iconify-icons/fluent/water-48-regular";
import Speed from "@iconify-icons/fluent/top-speed-24-regular";

const prefix = "/hydrology";
const nav = "telemeter";
export const HydrologyRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "hydrology",
  meta: { nav, title: "水文信息", icon: Water },
  redirect: { name: "station-query" },
  children: [
    {
      path: `${prefix}/flow-rate/query`,
      name: "flow-rate-query",
      meta: { nav, title: "流量管理", icon: WaterFlow },
      component: () => import("@/page/hydrology/flow-rate-query.vue"),
    },
    {
      path: `${prefix}/flow-velocity/query`,
      name: "flow-velocity-query",
      meta: { nav, title: "流速管理", icon: Speed },
      component: () => import("@/page/hydrology/flow-velocity-query.vue"),
    },
    {
      path: `${prefix}/rainfall/query`,
      name: "rainfall-query",
      meta: { nav, title: "雨量管理", icon: RainIcon },
      component: () => import("@/page/hydrology/rainfall-query.vue"),
    },
    {
      path: `${prefix}/voltage/query`,
      name: "voltage-query",
      meta: { nav, title: "电压管理", icon: CarBattery },
      component: () => import("@/page/hydrology/voltage-query.vue"),
    },
    {
      path: `${prefix}/water-level/query`,
      name: "water-level-query",
      meta: { nav, title: "水位管理", icon: WaterLevel },
      component: () => import("@/page/hydrology/water-level-query.vue"),
    },
  ],
};
