import dayjs from "dayjs";

/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn: Function, delay?: number) {
  let timer: number;
  return function () {
    // @ts-ignore
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay) as unknown as number;
  };
}

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time: number | string | Date, fmt: string): string {
  if (!time) return "";
  else {
    const date = new Date(time);
    const o: { [key: string]: number } = {
      "M+": date.getMonth() + 1,
      "d+": date.getDate(),
      "H+": date.getHours(),
      "m+": date.getMinutes(),
      "s+": date.getSeconds(),
      "q+": Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length),
      );
    for (const k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? `${o[k]}`
            : ("00" + o[k]).substr(("" + o[k]).length),
        );
      }
    }
    return fmt;
  }
}

export function formatStandardTime(text?: string | number): string {
  if (!text) {
    return "";
  }
  // if (typeof text === "string" && /Z$/.test(text)) {
  //   return dayjs(text.slice(0, -1)).format("YYYY-MM-DD HH:mm:ss");
  // }
  return dayjs(text).format("YYYY-MM-DD HH:mm:ss");
}
