<template>
  <el-form
    ref="ruleFormRef"
    :model="formModel"
    :rules="rules"
    status-icon
    label-position="top"
    @validate="handleValidate"
  >
    <el-form-item
      label="用户名"
      prop="username"
      required
      :error="errorProxy.username"
    >
      <el-input
        v-model="formModel.username"
        placeholder="用户名"
        autocomplete="on"
        autofocus
      />
    </el-form-item>
    <el-form-item
      label="密码"
      prop="password"
      required
      :error="errorProxy.password"
    >
      <el-input
        v-model="formModel.password"
        type="password"
        placeholder="密码"
        show-password
      />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        v-loading="loading"
        :disabled="disable"
        @click.stop="onSubmit"
        >登录
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { FormInstance, FormItemProp, FormRules } from "element-plus";

const props = defineProps<{
  loading: boolean;
  error: FormError<LoginDTO>;
}>();

const emit = defineEmits<{
  submit: [data: LoginDTO];
  // (e: "submit", data: Partial<PacketLog>): void;
}>();
const ruleFormRef = ref<FormInstance>();
const rules = reactive<FormRules<Partial<LoginDTO>>>({
  username: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
  password: [{ required: true, message: "密码不能为空", trigger: "blur" }],
});
const formModel = reactive<Partial<LoginDTO>>({
  // username: "rtu",
  // password: "zshq2023",
});
const localError = ref<FormError<LoginDTO>>({});

const disable = computed(() => !(formModel.username && formModel.password));
const errorProxy = computed(() => {
  const prop = props.error;
  const result: FormError<LoginDTO> = {};
  const local = localError.value;
  let key: keyof FormError<LoginDTO>;
  for (key in prop) {
    result[key] = result[key] ?? prop[key] ?? local[key];
  }
  for (key in local) {
    result[key] = result[key] ?? prop[key] ?? local[key];
  }
  return result;
});
const handleValidate = (
  prop: FormItemProp,
  isValid: boolean,
  message: string,
) => {
  console.log("form validate", prop, isValid, message);
  if (Array.isArray(prop)) {
    for (const p of prop) {
      localError.value = { ...localError.value, [p]: message };
    }
  } else {
    localError.value = { ...localError.value, [prop]: message };
  }
};
const onSubmit = async () => {
  if (disable.value) {
    return;
  }
  const form = ruleFormRef.value;
  if (!form) return;
  await form.validate((valid, fields) => {
    if (valid) {
      emit("submit", {
        username: formModel.username ?? "",
        password: formModel.password ?? "",
        captcha: formModel.captcha,
      });
    } else {
      console.log("error submit!", fields);
      // emit("update:error", model);
    }
  });
};
</script>

<style lang="scss" scoped></style>
