import { RouteRecordRaw } from "vue-router";
import Cloudy from "@iconify-icons/ep/cloudy";
import { TelemeterDeviceRoute } from "@/routes/telemeter/telemeter-device";
import { TelemeterStationRoute } from "@/routes/telemeter/telemeter-station";
import { HydrologyRoute } from "@/routes/telemeter/hydrology";

export const TelemeterRoutes: RouteRecordRaw[] = [
  TelemeterDeviceRoute,
  TelemeterStationRoute,
  HydrologyRoute,
];
export const TelemeterRoute: RouteRecordRaw = {
  path: "/telemeter",
  name: "telemeter",
  redirect: {
    name: "telemeter-device",
  },
  meta: { nav: "telemeter", title: "遥测管理", icon: Cloudy },
  component: () => import("@/components/common/page-menu-view.vue"),
  props: { routes: TelemeterRoutes },
  children: TelemeterRoutes,
};
