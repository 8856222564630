import { RouteRecordRaw } from "vue-router";
import Bell from "@iconify-icons/ep/bell";
import Document from "@iconify-icons/ep/document";

const prefix = "/log/fly";
const nav = "log";
export const FlyLogRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "fly-log",
  meta: { nav, title: "实时日志", icon: Document },
  redirect: { name: "fly-datagram-subscribe" },
  children: [
    {
      path: `${prefix}/subscribe`,
      name: "fly-datagram-subscribe",
      meta: { nav, title: "订阅", icon: Bell },
      component: () => import("@/page/log/packet/packet-subscribe.vue"),
    },
  ],
};
