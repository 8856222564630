import { createApp } from "vue";
// 必须放在 App 组件前面导入 不然样式会和 element ui 冲突
import "@/assets/css/base.scss";
import "uno.css";
import App from "@/App.vue";
import router from "@/routes";

// import "~/styles/element/index.scss";
import { createPinia } from "pinia";
// 如果使用 this.$message.success("操作成功") 这样的api  需要单独引入message组件及其样式 并单独注册组件
import { ElMessage } from "element-plus";

// import ElementPlus from "element-plus";
// import all element css, uncommented next line
// import "element-plus/dist/index.css";
// or use cdn, uncomment cdn link in `index.html`
// If you want to use ElMessage, import it.
// import "element-plus/theme-chalk/src/message.scss";
import "element-plus/es/components/message/style/css";
import "element-plus/theme-chalk/el-notification.css";
import "element-plus/theme-chalk/el-message.css";
import "element-plus/theme-chalk/el-message-box.css";
// import "@/mock/mock";
// import DataVVue3 from "@weoil/datav-vue3";
import "@/assets/css/public.scss";
// import "@/assets/css/index.scss";
const app = createApp(App);
// app.use(ElementPlus);
// app.use(DataVVue3);
// new vconsole({ defaultPlugins: ["network", "element"] });
app.use(ElMessage);
app.use(router);
app.use(createPinia());
app.mount("#app");
// app.config.unwrapInjectedRef = true;
