import { RouteRecordRaw } from "vue-router";
import Bell from "@iconify-icons/ep/bell";
import Search from "@iconify-icons/ep/search";
import Document from "@iconify-icons/ep/document";
import Monitor from "@iconify-icons/ep/monitor";

const prefix = "/log/datagram";
const nav = "log";
export const DatagramLogRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "datagram-log",
  meta: { nav, title: "报文帧日志", icon: Document },
  redirect: { name: "datagram-log-query" },
  children: [
    {
      path: `${prefix}/query`,
      name: "datagram-log-query",
      meta: { nav, title: "查询", icon: Search },
      component: () => import("@/page/log/packet/packet-log-query.vue"),
    },
    {
      path: `${prefix}/subscribe`,
      name: "datagram-log-subscribe",
      meta: { nav, title: "订阅", icon: Bell },
      component: () => import("@/page/log/packet/packet-log-subscribe.vue"),
    },
    {
      path: `${prefix}/remote`,
      name: "datagram-log-remote",
      meta: { nav, title: "设备会话", icon: Monitor },
      component: () => import("@/page/log/packet/packet-log-remote.vue"),
    },
  ],
};
