import { formatStandardTime } from "@/utils/date";

export function isSuccess<T, E>(
  result: ApiResult<T, E>,
): result is SuccessResult<T> {
  return result.code === 0 || result.code === 200;
}

export function isParamError<T, E>(
  result: ApiResult<T, E>,
): result is ParamErrorResult<E> {
  return result.code === 417;
}

export function formatBaseEntity<T extends BaseEntity>(it: T): T {
  if (!it.createdAt) {
    return { ...it };
  }
  return {
    ...it,
    createdAt: formatStandardTime(it.createdAt) ?? it.createdAt,
  };
}

export function formatUpdatableEntity<T extends UpdatableEntity>(it: T): T {
  if (!it.createdAt && !it.updatedAt) {
    return { ...it };
  }
  return {
    ...it,
    createdAt: formatStandardTime(it.createdAt) ?? it.createdAt,
    updatedAt: formatStandardTime(it.updatedAt) ?? it.updatedAt,
  };
}
