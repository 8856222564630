import { RouteRecordRaw } from "vue-router";
import Bell from "@iconify-icons/ep/bell";
import Search from "@iconify-icons/ep/search";
import Document from "@iconify-icons/ep/document";

const prefix = "/log/slt427";
const nav = "log";
export const SLT427LogRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "slt427-log",
  meta: { nav, title: "SLT427日志", icon: Document },
  redirect: { name: "slt427-log-query" },
  children: [
    {
      path: `${prefix}/query`,
      name: "slt427-log-query",
      meta: { nav, title: "查询", icon: Search },
      component: () => import("@/page/log/slt427/slt427-log-query.vue"),
    },
    {
      path: `${prefix}/subscribe`,
      name: "slt427-log-subscribe",
      meta: { nav, title: "订阅", icon: Bell },
      component: () => import("@/page/log/slt427/slt427-log-subscribe.vue"),
    },
    {
      path: `${prefix}/:id(\\d+)`,
      name: "slt427-log-detail",
      meta: {
        nav,
        title: "查询",
        menu: "slt427-log-query",
        icon: Document,
        hideInMenu: true,
      },
      props: (route) => {
        const id = parseInt(route.params.id as string);
        return { id, canBack: true, tab: route.hash.replace("#", "") };
      },
      component: () => import("@/page/log/slt427/slt427-detail-page.vue"),
    },
  ],
};
