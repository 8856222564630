import { RouteRecordRaw } from "vue-router";
import SchoolIcon from "@iconify-icons/ep/school";
import BuildingIcon from "@iconify-icons/ep/office-building";
import House from "@iconify-icons/mdi/house-automation";
import HouseSearch from "@iconify-icons/mdi/house-find-outline";
import HomeGroup from "@iconify-icons/mdi/home-group";

const prefix = "/telemeter/station";
const nav = "telemeter";
export const TelemeterStationRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "telemeter-station",
  meta: { nav, title: "遥测站点", icon: House },
  redirect: { name: "station-query" },
  children: [
    {
      path: `${prefix}/query`,
      name: "station-query",
      meta: { nav, title: "站点管理", icon: HouseSearch },
      component: () =>
        import("@/page/telemeter/station/telemeter-station-query.vue"),
    },
    {
      path: `${prefix}/group/query`,
      name: "station-group-query",
      meta: { nav, title: "分组管理", icon: HomeGroup },
      component: () =>
        import("@/page/telemeter/station/station-group-query.vue"),
    },
    {
      path: `${prefix}/:id(\\d+)`,
      name: "station-detail",
      meta: {
        nav,
        title: "测站详情",
        menu: "station-query",
        icon: SchoolIcon,
        hideInMenu: true,
      },
      props: (route) => {
        const id = parseInt(route.params.id as string);
        return { id, canBack: true, tab: route.hash.replace("#", "") };
      },
      component: () =>
        import("@/page/telemeter/station/station-detail-page.vue"),
    },
    {
      path: `${prefix}/group/:id(\\d+)`,
      name: "station-group-detail",
      meta: {
        nav,
        title: "分组详情",
        menu: "station-group-query",
        icon: BuildingIcon,
        hideInMenu: true,
      },
      props: (route) => {
        const id = parseInt(route.params.id as string);
        return { id, canBack: true };
      },
      component: () =>
        import("@/page/telemeter/station/station-group-detail-page.vue"),
    },
  ],
};
