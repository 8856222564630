import { RouteRecordRaw } from "vue-router";
import CpuIcon from "@iconify-icons/ep/cpu";
import CopyDocumentIcon from "@iconify-icons/ep/copy-document";
import OdometerIcon from "@iconify-icons/ep/odometer";
import SortIcon from "@iconify-icons/ep/sort";
import LightningIcon from "@iconify-icons/ep/lightning";
import Controller from "@iconify-icons/mdi/controller-variant-outline";

const prefix = "/telemeter/device";
const nav = "telemeter";
export const TelemeterDeviceRoute: RouteRecordRaw = {
  path: `${prefix}`,
  name: "telemeter-device",
  meta: { nav, title: "遥测设备", icon: CpuIcon },
  redirect: { name: "device-query" },
  children: [
    {
      path: `${prefix}/query`,
      name: "device-query",
      meta: { nav, title: "设备管理", icon: Controller },
      component: () =>
        import("@/page/telemeter/device/telemeter-device-query.vue"),
    },
    {
      path: `${prefix}/group/query`,
      name: "device-group-query",
      meta: { nav, title: "分组管理", icon: CopyDocumentIcon },
      component: () => import("@/page/telemeter/device/device-group-query.vue"),
    },
    {
      path: `${prefix}/firmware/query`,
      name: "device-firmware-query",
      meta: { nav, title: "固件管理", icon: LightningIcon },
      component: () =>
        import("@/page/telemeter/device/device-firmware-query.vue"),
    },
    {
      path: `${prefix}/upgrade-progress/subscribe`,
      name: "upgrade-progress-subscribe",
      meta: { nav, title: "升级进度", icon: SortIcon },
      component: () =>
        import("@/page/telemeter/device/upgrade-progress-subscribe.vue"),
    },
    {
      path: `${prefix}/:id(\\d+)`,
      name: "device-detail",
      meta: {
        nav,
        title: "设备详情",
        menu: "device-query",
        icon: OdometerIcon,
        hideInMenu: true,
      },
      props: (route) => {
        const id = parseInt(route.params.id as string);
        return { id, canBack: true, tab: route.hash.replace("#", "") };
      },
      component: () => import("@/page/telemeter/device/device-detail-page.vue"),
    },
    {
      path: `${prefix}/group/:id(\\d+)`,
      name: "device-group-detail",
      meta: {
        nav,
        title: "分组详情",
        menu: "device-group-query",
        icon: CopyDocumentIcon,
        hideInMenu: true,
      },
      props: (route) => {
        const id = parseInt(route.params.id as string);
        return { id, canBack: true };
      },
      component: () =>
        import("@/page/telemeter/device/device-group-detail-page.vue"),
    },
  ],
};
